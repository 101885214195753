// src/components/ui/button.js
import React from 'react';
import clsx from 'clsx';

export function Button({ children, className, ...props }) {
  return (
    <button
      className={clsx(
        'px-4 py-2 bg-primary text-white rounded hover:bg-primaryDark transition-colors',
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
}
