// src/components/ui/alert.js
import React from 'react';
import clsx from 'clsx';

export function Alert({ children, variant = 'default', className, ...props }) {
  const variants = {
    default: 'bg-gray-100 text-gray-800',
    success: 'bg-green-100 text-green-800',
    destructive: 'bg-red-100 text-red-800',
  };

  return (
    <div
      className={clsx(
        'p-4 rounded flex items-center',
        variants[variant],
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
