// src/components/CertificateComponent.js
import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import { Button } from './ui/button';

function CertificateComponent({ type, name, code }) {
  const certificateRef = useRef();

  const backgroundImage = `/images/${type.toLowerCase()}_certificate_template.svg`;

  const downloadCertificate = () => {
    html2canvas(certificateRef.current, { useCORS: true }).then((canvas) => {
      const link = document.createElement('a');
      link.download = `${type}_certificate_${code}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
    });
  };

  return (
    <div className="mb-8 text-center">
      <div
        className="relative mx-auto"
        style={{
          width: '800px',
          height: '600px',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        ref={certificateRef}
      >
        <div
          className="absolute"
          style={{
            top: '45%', // Adjust as needed
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '2em',
            fontWeight: 'bold',
            color: '#000',
          }}
        >
          {name}
        </div>
        <div
          className="absolute"
          style={{
            bottom: '10%', // Adjust as needed
            right: '45%',
            fontSize: '1em',
            color: '#d3d3d3',
          }}
        >
          {code}
        </div>
      </div>
      <Button onClick={downloadCertificate} className="mt-4">
        Download Certificate
      </Button>
    </div>
  );
}

export default CertificateComponent;
