// src/components/ui/card.js
import React from 'react';
import clsx from 'clsx';

export function Card({ children, className, ...props }) {
  return (
    <div
      className={clsx('bg-white p-6 rounded shadow', className)}
      {...props}
    >
      {children}
    </div>
  );
}
