// src/pages/CertificatePage.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CertificateComponent from '../components/CertificateComponent';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { Alert } from '../components/ui/alert';
import { Spinner } from '../components/ui/spinner';

function CertificatePage() {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [certificates, setCertificates] = useState([]);
  const [error, setError] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiOpacity, setConfettiOpacity] = useState(1);

  const query = new URLSearchParams(location.search);
  const code = query.get('code');

  const { width, height } = useWindowSize();

  useEffect(() => {
    if (code) {
      fetch(`https://script.google.com/macros/s/AKfycbySo8Czgre5Hi1J5cC43QFjAWJ57MM5wGoXuiay4mrmskAtp9nwnwx1XrMYhkIwWrtL/exec?code=${code}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          if (result.error) {
            setError(result.error);
          } else {
            setData(result);
            setIsVerified(true);
            determineCertificates(result.sub);
          }
        })
        .catch((error) => {
          console.error('Fetch error:', error);
          setError('Error fetching data');
        });
    }
  }, [code]);

  useEffect(() => {
    if (isVerified) {
      setShowConfetti(true);
      setConfettiOpacity(1);

      const confettiTimer = setTimeout(() => {
        const fadeDuration = 1000;
        const fadeSteps = 10;
        const fadeInterval = fadeDuration / fadeSteps;
        let opacity = 1;
        const fadeOutTimer = setInterval(() => {
          opacity -= 1 / fadeSteps;
          setConfettiOpacity(opacity);
          if (opacity <= 0) {
            clearInterval(fadeOutTimer);
            setShowConfetti(false);
          }
        }, fadeInterval);
      }, 5000);

      return () => {
        clearTimeout(confettiTimer);
      };
    }
  }, [isVerified]);

  const determineCertificates = (sub) => {
    let certs = [];
    if (sub.includes('a')) {
      certs.push('Aptitude');
    }
    if (sub.includes('t')) {
      certs.push('Technical');
    }
    if (sub.includes('s')) {
      certs.push('SoftSkills');
    }
    setCertificates(certs);
  };

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
        <Alert variant="destructive">{error}</Alert>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="container mx-auto py-8 px-4">
      {isVerified && (
        <>
          {showConfetti && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                opacity: confettiOpacity,
                transition: 'opacity 100ms linear',
              }}
            >
              <Confetti width={width} height={height} />
            </div>
          )}
          <Alert variant="success" className="mb-4">
            🎉 The certificate is verified!
          </Alert>
        </>
      )}
      <h1 className="text-3xl font-bold text-center mb-6 text-primary">
        Certificate Details
      </h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded shadow">
          <tbody>
            <tr className="border-b">
              <td className="px-6 py-4 font-semibold">Name</td>
              <td className="px-6 py-4">{data.name}</td>
            </tr>
            <tr className="border-b">
              <td className="px-6 py-4 font-semibold">Branch</td>
              <td className="px-6 py-4">{data.branch}</td>
            </tr>
            <tr className="border-b">
              <td className="px-6 py-4 font-semibold">Email</td>
              <td className="px-6 py-4">{data.mail}</td>
            </tr>
            <tr className="border-b">
              <td className="px-6 py-4 font-semibold">Phone</td>
              <td className="px-6 py-4">{data.phone}</td>
            </tr>
            <tr className="border-b">
              <td className="px-6 py-4 font-semibold">Roll Number</td>
              <td className="px-6 py-4">{data.roll}</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-semibold">Certificate Code</td>
              <td className="px-6 py-4">{data.code}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-8">
        {certificates.map((type) => (
          <CertificateComponent
            key={type}
            type={type}
            name={data.name}
            code={data.code}
          />
        ))}
      </div>
    </div>
  );
}

export default CertificatePage;
