// src/components/ui/label.js
import React from 'react';
import clsx from 'clsx';

export function Label({ children, className, ...props }) {
  return (
    <label className={clsx('block text-gray-700 mb-1', className)} {...props}>
      {children}
    </label>
  );
}
